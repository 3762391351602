import React, { Component } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { Marker, GroundOverlay, Circle } from '@react-google-maps/api';
import * as Constants from './Constants.js';

const mapStyles = {
    width: '100%',
    height: '100%',
};

const zoomLevel = 8;

const options = {
    disableDefaultUI: true,
    gestureHandling: 'none'
}

export default class MapTMS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            north: 0,
            south: 0,
            east: 0,
            west: 0,
            loaded: false,
            strikes: null
        };
        this.myRef = React.createRef();
        this.groundOverlay = React.createRef();
        this.boundsChange = this.boundsChange.bind(this);
    }

    refreshInterval = undefined;
    getMapData = () =>
    {
        const node = this.myRef.current;
        var map = node.state.map;

        var bounds = map.getBounds();
        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();

        var w = map.getDiv().offsetWidth;
        var h = map.getDiv().offsetHeight;

        var n = ne.lat();
        var s = sw.lat();
        var e = ne.lng();
        var west = sw.lng();

        var zoom = map.getZoom();

        // Fetch map data
        Promise.all([
            fetch(Constants.ApiUrl + 'weather/wms?neLat=' + ne.lat() + '&neLong=' + ne.lng() + '&swLat=' + sw.lat() + '&swLong=' + sw.lng() + '&width=' + w + '&height=' + h),
            fetch(Constants.ApiUrl + 'weather/strikes?top=' + ne.lat() + '&right=' + ne.lng() + '&bottom=' + sw.lat() + '&left=' + sw.lng() + '&zoom=' + zoom)
            //fetch('https://tablet-api.pocketperry.com/baron/wms?neLat=' + ne.lat() + '&neLong=' + ne.lng() + '&swLat=' + sw.lat() + '&swLong=' + sw.lng() + '&width=' + w + '&height=' + h),
            //fetch('https://tablet-api.pocketperry.com/baron/strikes?top=' + ne.lat() + '&right=' + ne.lng() + '&bottom=' + sw.lat() + '&left=' + sw.lng() + '&zoom=' + zoom)
        ])
        .then(([res1, res2]) => Promise.all([res1.text(), res2.json()]))
        .then(([data1, data2]) => this.setState({data: data1, loaded: true, north: n, south: s, east: e, west: west, strikes: data2}));

        // Refresh map every 5 min
        this.refreshInterval = setTimeout(this.getMapData.bind(this), 5*60000)
    }

    loading(args) 
    {
        console.log(args);
    }

    boundsChange = () =>
    {
        if (this.state.loaded === false )
        {
            this.getMapData()
        }
    }

    render () {
        const { data, loaded, north, south, east, west } = this.state;
        if (loaded === true) {
            return ( 
                <div style={{ height: '100%', width: '100%' }}>
                    <LoadScript id="script-loader">
                        <GoogleMap
                            ref={this.myRef}
                            mapContainerStyle={
                                mapStyles
                            }
                            options={options}
                            zoom={zoomLevel}
                            onBoundsChanged={this.boundsChange}
                            onLoad={this.boundsChange}
                            center={{lat: this.props.locationData.latitude, lng: this.props.locationData.longitude}}
                        >
                            <GroundOverlay
                                key={'url'}
                                url={data}
                                onLoad={this.test}
                                opacity={0.9}
                                bounds={{
                                    north: north,
                                    south: south,
                                    east: east,
                                    west: west,
                                }}
                            />

                            <Circle
                                center={{
                                    lat: this.props.locationData.latitude,
                                    lng: this.props.locationData.longitude
                                }}
                                options={{
                                    strokeColor: '#FF0000',
                                    strokeOpacity: 1,
                                    strokeWeight: 2,
                                    fillOpacity: 0.0,
                                    clickable: false,
                                    draggable: false,
                                    editable: false,
                                    visible: true,
                                    radius: this.props.locationData.radius1 * 1609.34,
                                    zIndex: 1
                                }}
                            />

                            <Circle
                                center={{
                                    lat: this.props.locationData.latitude,
                                    lng: this.props.locationData.longitude
                                }}
                                options={{
                                    strokeColor: '#FFFF00',
                                    strokeOpacity: 1,
                                    strokeWeight: 2,
                                    fillOpacity: 0.0,
                                    clickable: false,
                                    draggable: false,
                                    editable: false,
                                    visible: true,
                                    radius: this.props.locationData.radius2 * 1609.34,
                                    zIndex: 1
                                }}
                            />

                            <Circle
                                center={{
                                    lat: this.props.locationData.latitude,
                                    lng: this.props.locationData.longitude
                                }}
                                options={{
                                    strokeColor: '#00FF00',
                                    strokeOpacity: 1,
                                    strokeWeight: 2,
                                    fillOpacity: 0.0,
                                    clickable: false,
                                    draggable: false,
                                    editable: false,
                                    visible: true,
                                    radius: this.props.locationData.radius3 * 1609.34,
                                    zIndex: 1
                                }}
                            />

                            <Marker
                                position={{ lat: this.props.locationData.latitude, lng: this.props.locationData.longitude }}
                                options={{size: .5}}
                            />

                            {this.state.strikes.map(marker => 
                                {
                                    if (marker.Age < 300)
                                    {
                                        return (<Marker
                                            position={{ lat: parseFloat(marker.Latitude), lng: parseFloat(marker.Longitude) }}
                                            icon={"https://images.pocketperry.com/map_images/RedStrike.png"}
                                            key={Math.random()}
                                        />)
                                    }
                                    else if (marker.Age < 900)
                                    {
                                        return (<Marker
                                            position={{ lat: parseFloat(marker.Latitude), lng: parseFloat(marker.Longitude) }}
                                            icon={"https://images.pocketperry.com/map_images/YellowStrike.png"}
                                            key={Math.random()}
                                        />)
                                    }
                                    else 
                                    {
                                        return (<Marker
                                            position={{ lat: parseFloat(marker.Latitude), lng: parseFloat(marker.Longitude) }}
                                            icon={"https://images.pocketperry.com/map_images/GreenStrike.png"}
                                            key={Math.random()}
                                        />)
                                    }
                                }
                            )}
                        </GoogleMap>
                    </LoadScript>
                </div>
            );
        }
        else {
            console.log("Map!!! Lat: " + this.props.locationData.latitude + ", Long: " + this.props.locationData.longitude);
            return ( 
                 <div style={{ height: '50vh', width: '100%' }}>
                    <LoadScript
                    id="script-loader"
                    googleMapsApiKey="AIzaSyC_mrAWbyD1cK_p5HUtDcMsKmOXU7MUIlE"
                  >
                    <GoogleMap
                        ref={this.myRef}
                        mapContainerStyle={
                            mapStyles
                        }
                        options={options}
                        zoom={zoomLevel}
                        onBoundsChanged={this.boundsChange}
                        //onUnloaded={this.boundsChange}
                        //onLoad={this.boundsChange}
                        //onProjectionChanged={this.boundsChange}
                        center={{lat: this.props.locationData.latitude, lng: this.props.locationData.longitude}} >

                    </GoogleMap>
                </LoadScript>
                </div>
            );

        }
    }
}
