import React from 'react';


function Separator(props) {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${props.turns}turn)`
      }}
    >
      <div style={props.style} />
    </div>
  );
}

function RadialSeparators(props) {
  const turns = 1 / props.count;

  return Array.from({length: props.count}, (_, index) => 
  (
    <Separator key={`${index}/${props.count}`} turns={index * turns} style={props.style} />
  )); // output: [0, 1, 2, 3]

  /*return _.range(props.count).map(index => (
    <Separator turns={index * turns} style={props.style} />
  ));*/
}

export default RadialSeparators;

