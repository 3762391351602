
import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import MapTMS from './MapTMS.js';
import LightningTimer from './LightningTimer.js';
import CurrentConditions from './CurrentConditions.js';
import * as Constants from './Constants.js';
import './App.css';

class MainContainer extends Component {
    intervalID;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            locationData: null,
            locationId: window.location.pathname.substr(1),
            params: null
            //locationId: '49997b01-41eb-421a-903c-216875e1b063'
        };
    }

    componentDidMount()
    {
        console.log(this.state.params);
        this.setState({params: Object.fromEntries(new URLSearchParams(window.location.search))});
        this.getData();
    }

    componentWillUnmount()
    {
        clearTimeout(this.intervalID);
    }

    getData = () => {
        console.log('Sending request to: ' + Constants.ApiUrl + `location/${this.state.locationId}`)
        fetch( Constants.ApiUrl + `location/${this.state.locationId}`)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("Finished loading location data: " + JSON.stringify(result, null, 2));
                // Status is only returned when the request fails, so set bad result when it exists
                if (result.status) {
                    this.setState({
                        isLoaded:true,
                        error:JSON.stringify(result, null, 2)
                    });
                    console.log("Request with bad response code: " + JSON.stringify(`api/location/${this.state.locationId}`))
                    console.log("Bad response code when fetching data: " + JSON.stringify(result, null, 2));
                }
                else {
                    this.setState({
                        isLoaded:true,
                        locationData:result
                    });
                    this.intervalID = setTimeout(this.getData.bind(this), 300000);
                }
            },
            (error) => {
                this.setState({
                    isLoaded:true,
                    error:JSON.stringify(error, null, 2)
                });
                console.log(error);
            })
    }

    render() {
        const { error, isLoaded, locationData, params } = this.state;
        if (!isLoaded)
        {
            console.log("App not loaded");
            return <div>Loading... </div>;
        }
        else if (error)
        {
            return <div>Error: {error}</div>;
        }
        else {
            if (params.lightningonly !== undefined) {
                return(
                        <Row className="">
                            <Col sm={12} className="second">
                                <Row>
                                    <LightningTimer locationData={locationData}/>
                                </Row>
                            </Col>
                        </Row>
                );
            }
            else if (params.conditiononly !== undefined) {
                return (
                        <Row className="">
                            <Col md className="first">
                                <Row className="conditions">
                                    <CurrentConditions locationData={locationData} />
                                </Row>
                            </Col>
                        </Row>
                );
            }
            else if (params.maponly !== undefined) {
                return (
                        <Row className="mt-5">
                            <Col md className="first">
                                <Row className="map">
                                    <MapTMS locationData={locationData} />
                                </Row>
                            </Col>
                        </Row>
                );
            }
            else {
                // console.log("App loaded: " + locationData.latitude)
                return (
                        <Row className="">
                            <Col sm={4} className="mt-5 first">
                                <Row className="map">
                                    <MapTMS locationData={locationData} />
                                </Row>
                                <hr className="break"/>
                                <Row className="conditions">
                                    <CurrentConditions locationData={locationData} />
                                </Row>
                            </Col>
                            <Col sm={8} className="second">
                                <Row>
                                    <LightningTimer locationData={locationData}/>
                                </Row>
                            </Col>
                        </Row>
                );
            }
        }
    }
}

export default MainContainer;
