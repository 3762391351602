
import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './App.css';
import './WidgetMoving.css';

class MovingContainer extends Component {
    intervalID;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            locationData: null,
            params: null
        };
    }

    componentDidMount()
    {
    }

    componentWillUnmount()
    {
    }

    render() {
		return(
		<Container className='mt-4'>
			<Row className="justify-content-center moving-text">
				<Col>
					<strong className="center font-weight-bold">
						This widget is no longer available! Please contact support@perryweather.com
					</strong>
				</Col>
			</Row>
		</Container>
		);
    }
}

export default MovingContainer;
