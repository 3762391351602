
import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import './DeprecatedBanner.css';

class DeprecatedBanner extends Component {

    render () {       
        return ( 
            <Container fluid={true} className="banner">
                <Row>
                Upgrade your widget! This widget style expires 11/20. For help, contact support@perryweather.com
                </Row>
            </Container>
        );
        
    }
}

export default DeprecatedBanner;

