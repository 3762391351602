import React, { Component } from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import Moment from 'react-moment';
import moment from "moment";
import * as Constants from './Constants.js';
import './CurrentConditions.css';

Moment.globalLocal = true;

class CurrentConditions extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            observations: null,
            lastUpdated: new moment(),
        };
    }

    observationInterval;

    componentDidMount()
    {
        this.getObservations();
    }

    componentWillUnmount()
    {
        clearTimeout(this.observationInterval);
    }

    getObservations = () => {
        fetch(Constants.ApiUrl + "weather/observation?latitude=" + this.props.locationData.latitude + "&longitude=" + this.props.locationData.longitude)
        //fetch("https://tablet-api.pocketperry.com/baron/observation?latitude=" + this.props.locationData.latitude + "&longitude=" + this.props.locationData.longitude)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    observations: result,
                    lastUpdated: new moment()
                });
                this.observationInterval = setTimeout(this.getObservations.bind(this), 5 * 60000); // Update conditions every 5 minutes
                console.log(result);
            },
            (error) => {
                this.setState({
                    isLoaded:true,
                    error: error
                });
                console.log(error);
            });
    }

    render () {
        const { error, isLoaded, observations, lastUpdated } = this.state;
        if (!isLoaded)
        {
            return <div>Loading...</div>;
        }
        else if (isLoaded) 
        {
            // Format weather code to have fixed length of 4
            const weatherCode = observations.weatherCode.toString().padStart(4, '0');
            return ( 
                <Container fluid={true} className="mt-3 h-100 justify-content-center main">
                    <Row>
                        <Col xs={12} className="icon-wrapper">
                            <p className="label-text">
                                {this.props.locationData.label}
                            </p>
                        </Col>
                    </Row>
                    <Row className="weather">
                        <Col className="icon-wrapper">
                            <Image
                                className="icon"
                                src={`https://images.pocketperry.com/weather_icons/${weatherCode}.png`}
                                alt={`weatherCode${weatherCode}`} 
                                fluid
                            />
                        </Col>
                        <Col className="temperature">
                            <div className="temp-text">
                                {Math.round(observations.temperature)}°F
                            </div>
                        </Col>
                    </Row>
                    <Row className="info-text">
                        <Col>
                            {Math.round(observations.windSpeed)} {observations.windDirection}
                        </Col>
                    </Row>
                    <Row className="info-text">
                        <Col>
                            24-Hr Rain: {Math.round(observations.rainTotal * 100) / 100}"
                        </Col>
                    </Row>
                    <Row className="info-text">
                        <Col>
                            FEELS: {Math.round(observations.feelsLike)}
                        </Col>
                    </Row>
                    <Row className="last-updated">
                        <Col>
                            <p className="">
                                Last updated <Moment fromNow>{lastUpdated}</Moment>
                                &nbsp;@&nbsp;<Moment local format="h:mma">{lastUpdated}</Moment>
                                <br/>
                                {observations.station ? " from " + observations.station : ""}
                            </p>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={12} >
                            <Image className="logo" src="/images/logo.png" alt="logo" fluid/>
                        </Col>
                    </Row>
                </Container>
            );
        }
        else 
        {
            return <div>{error}</div>;
        }
    }
}

export default CurrentConditions;

