import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import RadialSeparators from './RadialSeparators.js';
import * as Constants from './Constants.js';

import 'react-circular-progressbar/dist/styles.css';
import './LightningTimer.css';

class LightningTimer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            delayStatus: null,
            percentage: 100,
            imageSrc: "images/lightning-green.png",
            imageAlt: "Green Lightning",
            imageVisible: "visible",
        };
    }

    // Need time in seconds
    time = this.props.locationData.allClearMinutes * 60;

    // Repeating functions
    countdownInterval = undefined;
    imageFlash = undefined;
    getDelayInterval = undefined;

    convertSecondsToTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        const minuteString = `${minutes < 10 ? `0${minutes}` : minutes}`;
        const secondString = `${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
        const time = `${minuteString}:${secondString}`;
        return time;
    }

    startCountdown = () => {
        this.countdownInterval = window.setInterval(this.tickCountdown, 1000);
    }

    resetCountdown = () => {
        this.setState({
            delayStatus: this.time,
            percentage: 100,
            imageSrc: "images/lightning-green.png",
            imageAlt: "Green Lightning",
            imageVisible: "visible",
        });
        window.clearInterval(this.countdownInterval);
        window.clearInterval(this.imageFlash);
    }

    tickCountdown = () => {
        if (this.state.delayStatus > 0) {
            this.setState((state, _) => ({ 
                delayStatus: state.delayStatus - 1,
                percentage: ((state.delayStatus - 1) / this.time) * 100,
                imageSrc: this.getImageProps(state.delayStatus - 1).src,
                imageAlt: this.getImageProps(state.delayStatus - 1).alt,
            }));

            if (this.imageFlash === undefined && this.state.percentage < 100) {
                this.imageFlash = window.setInterval(this.flashImage, 500);
            }
        }
        else { // If we reach the end of the timer, reset it
            this.resetCountdown();
        }
    }

    flashImage = () => {
        if (this.state.percentage === 0) {
            this.setState({
                imageVisible: "visible"
            });
            window.clearInterval(this.imageFlash);
        }
        else {
            this.setState((state, _) => ({
                imageVisible: state.imageVisible === "visible" ? "hidden" : "visible",
            }));
        }
    }

    getImageProps(delayStatus) {
        // Have to calculate percentage because we call this inside setState
        const percentage = (delayStatus / this.time) * 100;
        var src = null;
        var alt = null;
        if (percentage === 100) {
            src = "images/lightning-green.png";
            alt = "Green Lightning";
        }
        else if (percentage < 100 && percentage >= 50) {
            src = "images/lightning-red.png";
            alt = "Red Lightning";
        }
        else {
            src = "images/lightning-yellow.png";
            alt = "Yellow Lightning";
        }

        return { src, alt };
    }

    componentDidMount()
    {
        this.getDelayStatus();
    }

    getDelayStatus = () => {
        fetch(Constants.ApiUrl + "location/delay/" + this.props.locationData.locationId)
        .then(res => res.json())
        .then(
            (result) => {
                const currentTime = result.totalSeconds === 0 ? this.time : result.totalSeconds;
                const imageProps = this.getImageProps(currentTime);
                this.setState({
                    delayStatus: result.totalSeconds,
                    imageSrc: imageProps.src,
                    imageAlt: imageProps.alt
                });
                console.log(result);
                this.getDelayInterval = setTimeout(this.getDelayStatus.bind(this), 30000); // Update conditions every 30 seconds

                // If there's a current delay, start countdown
                if (this.state.delayStatus > 0 && this.countdownInterval === undefined) {
                    this.startCountdown();
                }
            },
            (error) => {
                this.setState({
                    error: error
                });
                console.log(error);
            })
    }

    componentWillUnmount()
    {
        window.clearInterval(this.countdownInterval);
        window.clearInterval(this.imageFlash);
        window.clearTimeout(this.getDelayInterval);
    }

    render () {
        const { delayStatus, percentage, imageSrc, imageAlt, imageVisible } = this.state;
        return ( 
            <Container className='mt-4'>
                <Row className="justify-content-center timer-text">
                    <Col>
                        <strong className="text-center font-weight-bold">
                            0-{this.props.locationData.radius1} mi
                        </strong>
                    </Col>
                </Row>
                <Row className="justify-content-center align-content-center timer">
                    <Col>
                        <div className="spinner half">
                            <CircularProgressbarWithChildren
                                counterClockwise={true} 
                                value={percentage}
                                strokeWidth={10}
                                styles={buildStyles({
                                    strokeLinecap: 'butt',
                                    pathColor: '#f3b946',
                                    pathTransition: "stroke-dashoffset 0.5s ease"
                                })}
                            >
                                <Image
                                    src={imageSrc}
                                    alt={imageAlt}
                                    style={{
                                        marginTop: -10, 
                                        maxHeight: "30vh",
                                        visibility: `${imageVisible}`
                                    }}
                                    fluid
                                />
                                <div style={{ fontSize: 12, marginTop: "-5%" }}>
                                    <strong className="internal-timer-text">
                                        {(delayStatus === 0 || delayStatus === undefined) ? "Clear!" : this.convertSecondsToTime(delayStatus)}
                                    </strong>
                                </div>
                                <RadialSeparators
                                    count={12}
                                    style={{
                                        background: '#adadad',
                                        width: '4px',
                                        height: `${10}%` // Needs to be equal to props.strokeWidth
                                    }}
                                />
                                <RadialSeparators
                                    count={60}
                                    style= {{
                                        background: '#adadad',
                                        width: '4px',
                                        height: `${3}%` // Needs to be equal to props.strokeWidth
                                    }}
                                />
                            </CircularProgressbarWithChildren>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center timer-name">
                    <p className="text-center font-weight-bold">
                        LIGHTNING STATUS
                    </p>
                </Row>
            </Container>
        );
    }
}

export default LightningTimer;
