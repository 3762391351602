import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import DeprecatedBanner from './DeprecatedBanner.js'
import MovingContainer from './WidgetMoving.js'
import MainContainer from './MainContainer.js';


class App extends Component{
   

    render() {
     return (
        <>
        {/* <DeprecatedBanner/> */}
        <Container fluid={true} className="App">
            <MovingContainer/>
            {/* <MainContainer/> */}
        </Container>
        </>
     )
    }
}

export default App;
